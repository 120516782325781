import React, { useState } from 'react'
import './LetsTalk.scss'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function LetsTalk(props) {

  const [data, setData] = useState({
    name: "",
    email: "",
    message: ""
  });

  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/contact`;
      await axios.post(url, data);
      toast.success('Thank you for reaching out to us! Your message has been sent successfully. We appreciate your interest and will get back to you as soon as possible.');
      setData({
        name: "",
        email: "",
        message: ""
      });
    } catch (error) {
      console.error("error", error);
      toast.error(error.message);
    }
  };

  return (
    <div className='lets_talk' data-aos="fade-left">
      {
        props.title ? <div className="title">Let's bring it to life together!</div> : ''
      }
      <form onSubmit={e => handleSubmit(e)}>
        <input type="text" required className={`${props.title ? '' : 'mt-0'}`} placeholder='Full Name' value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
        <input type="email" required placeholder='Email' value={data.email} onChange={e => setData({ ...data, email: e.target.value })} />
        <textarea required cols="30" rows="9" placeholder='Message' value={data.message} onChange={e => setData({ ...data, message: e.target.value })} />
        <button type='submit'>Let’s Talk Us</button>
      </form>
    </div>
  )
}
