import React from 'react'
import FooterLogo from './../../assets/images/footer_logo.svg'
import Clutch from './../../assets/images/clutch.png'
import Instagram from './../../assets/images/instagram.svg'
import Facebook from './../../assets/images/facebook.svg'
import Twitter from './../../assets/images/twitter.svg'
import LinkedIn from './../../assets/images/linkedin.svg'
import YouTube from './../../assets/images/youtube.svg'
import './Footer.scss'
import { NavLink } from 'react-router-dom'
import TagManager from 'react-gtm-module'

export default function Footer() {

  const ClickHome = () =>{
    TagManager.dataLayer({ 
      dataLayer: {
        event: 'Footer Home Click'
      },
    });
  }
  const ClickAbout = () =>{
    TagManager.dataLayer({ 
      dataLayer: {
        event: 'Footer About Click'
      },
    });
  }
  const ClickServices = () =>{
    TagManager.dataLayer({ 
      dataLayer: {
        event: 'Footer Services Click'
      },
    });
  }
  const ClickIndustries = () =>{
    TagManager.dataLayer({ 
      dataLayer: {
        event: 'Footer Industries Click'
      },
    });
  };
  const ClickOurWork = () =>{
    TagManager.dataLayer({ 
      dataLayer: {
        event: 'Footer Our-Work Click'
      },
    });
  }
  const ClickOurClients = () =>{
    TagManager.dataLayer({ 
      dataLayer: {
        event: 'Footer Our-Clients Click'
      },
    });
  }
  const ClickCaseStudies = () =>{
    TagManager.dataLayer({ 
      dataLayer: {
        event: 'Footer Case-Studies Click'
      },
    });
  }
  const ClickContact = () =>{
    TagManager.dataLayer({ 
      dataLayer: {
        event: 'Footer Contact Click'
      },
    });
  }
  const ClickCareer = () =>{
    TagManager.dataLayer({ 
      dataLayer: {
        event: 'Footer Career Click'
      },
    });
  }

  return (
    <footer>
      <div className="social_icons">
        <a href='https://www.facebook.com/koolmindtech' target='_blank' className="social_box">
          <img src={Facebook} alt="" />Facebook
        </a>
        <a href='https://www.youtube.com/@koolmindtechnolab' target='_blank' className="social_box">
          <img src={YouTube} alt="" />Youtube
        </a>
        <a href='https://www.instagram.com/koolmindtechnolab/' target='_blank' className="social_box">
          <img src={Instagram} alt="" />Instagram
        </a>
        <a href='https://clutch.co/profile/koolmind-technolab-llp' target='_blank' className="social_box">
          <img src={Clutch} alt="" />Clutch
        </a>
        <a href='https://x.com/koolmindtech' target='_blank' className="social_box">
          <img src={Twitter} alt="" />Twitter
        </a>
        <a href='https://www.linkedin.com/company/koolmind/mycompany/' target='_blank' className="social_box">
          <img src={LinkedIn} alt="" />Linkedin
        </a>
      </div>
      <div className="footer_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="top">
                <div className="logo"><img src={FooterLogo} alt="" /></div>
                <div className="content">
                  <div className="title">Leading companies trust us</div>
                  {/* <div className="desc">KoolMind Technolab is the one-stop solution for all kinds of Mobile Apps, Web Apps & Software Development services.</div> */}
                </div>
              </div>
            </div>
            <div className="bottom">
              <div className="row">
                <div className="col-md-12">
                  {/* <div className="link_title">Company</div> */}
                  <div className="links">
                    <NavLink to="/" onClick={ClickHome}>Home</NavLink>
                    <NavLink to="/about" onClick={ClickAbout}>About Us</NavLink>
                    <NavLink to="/services" onClick={ClickServices}>Services</NavLink>
                    <NavLink to="/industries" onClick={ClickIndustries}>Industries</NavLink>
                    <NavLink to="/our-works" onClick={ClickOurWork}>Our Works</NavLink>
                    <NavLink to="/our-clients" onClick={ClickOurClients}>Our Clients</NavLink>
                    <NavLink to="/case-studies" onClick={ClickCaseStudies}>Case Studies</NavLink>
                    <NavLink to="/contact" onClick={ClickContact}>Contact Us</NavLink>
                    <NavLink to="/career" onClick={ClickCareer}>Career</NavLink>
                  </div>
                </div>
                {/* <div className="col-md-4 col-lg-3 col-xl-2">
                  <div className="link_title">Mobile App Development </div>
                  <div className="links">
                    <a href="">iOS App Development</a>
                    <a href="">Android App Development</a>
                    <a href="">React Native Development</a>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 col-xl-2">
                  <div className="link_title">UI & UX Design Services</div>
                  <div className="links">
                    <a href="">Wireframe Design</a>
                    <a href="">Website Design</a>
                    <a href="">Android App Design</a>
                    <a href="">IOS App Design</a>
                    <a href="">Logo Design</a>
                    <a href="">Brochure and Flyer Design</a>
                    <a href="">Web App Design</a>
                    <a href="">Social Media Page/Cover</a>
                    <a href="">Packaging Design</a>
                    <a href="">Animation Video</a>
                    <a href="">Branding Design</a>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3 col-xl-2">
                  <div className="link_title">Back-end Development</div>
                  <div className="links">
                    <a href="">PHP Web Development</a>
                    <a href="">Laravel Web Development</a>
                    <a href="">Wordpress Development</a>
                    <a href="">Shopify Development</a>
                    <a href="">E-Commerce Web Development</a>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 mt-4">
                <div className="link_title">Get Latest Updates</div>
                <div className="update_form">
                  <input type="text" name="" id="" placeholder='Email'/>
                  <button>Subscribe</button>
                </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy_right">
        <p>ALL RIGHTS RESERVED ©2023 KOOLMIND</p>
      </div>
    </footer>
  )
}
