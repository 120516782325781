import React from "react";

const WhatWeBelive = () => {
    return (
        <section className="what_we_believe">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading">
                            <h2>What We Believe</h2>
                            <p>At KoolMind Technolab, we believe in delivering End-to-End Mobile App Solutions with Full Responsibility. We create Simplified Portable apps that offer seamless experiences, ensuring every project is handled with utmost care from concept to launch, surpassing client expectations.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhatWeBelive;
