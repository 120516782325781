import React, { useState } from 'react'
import jsondata from "./../../../Data/Case_Studies.json";
import { useLocation } from 'react-router-dom';
export default function VisualInterface({id, selectedApp}) {
    const [btnAction, setBtnAction] = useState("Mobile");
    const location = useLocation();

    console.log("location", location);

  return (
    <section className='visual_interface'>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="title" data-aos="fade-up">Amazing visual interface</div>
                    <div className="desc" data-aos="fade-up">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor tempor incididunt ut labore dolore magna.</div>
                    
                    <div className="app_tabs" data-aos="fade-up">
                        <button className={`${btnAction === "Mobile" ? 'active' : ''}`} onClick={e => setBtnAction("Mobile")}>
                            <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.652 0.666992H5.34537C1.66536 0.666992 0.332031 2.00033 0.332031 5.74699V22.2537C0.332031 26.0003 1.66536 27.3337 5.34537 27.3337H16.6387C20.332 27.3337 21.6654 26.0003 21.6654 22.2537V5.74699C21.6654 2.00033 20.332 0.666992 16.652 0.666992ZM10.9987 23.7337C9.7187 23.7337 8.66537 22.6803 8.66537 21.4003C8.66537 20.1203 9.7187 19.067 10.9987 19.067C12.2787 19.067 13.332 20.1203 13.332 21.4003C13.332 22.6803 12.2787 23.7337 10.9987 23.7337ZM13.6654 6.33366H8.33203C7.78536 6.33366 7.33203 5.88033 7.33203 5.33366C7.33203 4.78699 7.78536 4.33366 8.33203 4.33366H13.6654C14.212 4.33366 14.6654 4.78699 14.6654 5.33366C14.6654 5.88033 14.212 6.33366 13.6654 6.33366Z"/>
                            </svg>
                            <span>Mobile Apps</span>
                        </button>
                        {
                            location?.pathname?.includes("alltails") && 
                        <button className={`${btnAction === "Web" ? 'active' : ''}`} onClick={e => setBtnAction("Web")}>
                            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.9867 13.5066C27.5333 13.3199 26.9333 13.2266 26.1867 13.2266H19.1467C16.8267 13.2266 16 14.0532 16 16.3999V26.7199C16 27.4932 16.0933 28.0932 16.2933 28.5599C16.7067 29.5199 17.5867 29.8932 19.1467 29.8932H26.1867C28.5067 29.8932 29.3333 29.0532 29.3333 26.7199V16.3999C29.3333 14.8132 28.96 13.9199 27.9867 13.5066ZM24 26.8932H21.3333C21.3067 26.8932 21.2667 26.8932 21.24 26.8799C21.04 26.8666 20.8667 26.7999 20.72 26.6666C20.48 26.4932 20.3333 26.2132 20.3333 25.8932C20.3333 25.3466 20.7867 24.8932 21.3333 24.8932H24C24.5467 24.8932 25 25.3466 25 25.8932C25 26.4399 24.5467 26.8932 24 26.8932Z"/>
                                <path d="M27.9864 8.81323V9.89323C27.9864 10.6266 27.3864 11.2266 26.653 11.2266H19.1464C15.733 11.2266 13.9997 12.9732 13.9997 16.3999V28.5599C13.9997 29.2932 13.3997 29.8932 12.6664 29.8932H10.0664C9.53302 29.8932 9.10635 29.4666 9.10635 28.9466C9.10635 28.4132 9.53302 27.9999 10.0664 27.9999H12.6664V22.8932H7.99969C5.03969 22.7466 2.67969 20.3066 2.67969 17.3066V8.81323C2.67969 5.73323 5.18635 3.22656 8.27969 3.22656H22.3997C25.4797 3.22656 27.9864 5.73323 27.9864 8.81323Z"/>
                            </svg>
                            <span>Web Apps</span>
                        </button>
                        }

                    </div>

                    <div className="visual_image"  data-aos="fade-left" >
                    {
                        btnAction === "Web" ? 
                        <img src={`${process.env.PUBLIC_URL}/images/visual/alltails_web.png`} alt="" /> :
                        <img src={`${process.env.PUBLIC_URL}${selectedApp.visual_image}`} alt="" />
                    }
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
