import React, { useEffect } from "react";
import Go from "./../../../assets/images/go.svg";
import GoBlue from "./../../../assets/images/blue_go.svg";
import PlayStore from "./../../../assets/images/play.svg";
import website from "./../../../assets/images/website.svg";
import AppStore from "./../../../assets/images/app.svg";
import Case1 from "./../../../assets/images/Carousel_Groupus.png";
import Case2 from "./../../../assets/images/Carousel_salamatk.png";
import Case3 from "./../../../assets/images/Carousel_Abees.png";
import Case4 from "./../../../assets/images/avniiscribe.png";
import Case5 from "./../../../assets/images/alltails.png";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import { Link, NavLink, useParams } from "react-router-dom";
import TagManager from "react-gtm-module";

export default function CaseStudies() {
  const options = {
    items: 2.5,
    nav: false,
    rewind: false,
    dots: true,
    autoplay: false,
    margin: 10,
    center: true,
    responsive: {
      0: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1024: {
        items: 1.5,
      },
      1400: {
        items: 2.3,
      },
    },
  };

  const handleClickGroupus = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Home Case-study Click Groupus",
      },
    });
  };
  const handleClickSalamatk = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Home Case-study Click Salamatk",
      },
    });
  };
  const handleClickAbees = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "Home Case-study Click Abees",
      },
    });
  };

  return (
    <section className="case_studies">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="heading"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="title">Case Studies</div>
              <div className="des">
                Browse our completed projects and see how we’ve helped
                businesses reach new heights through our innovative solutions
              </div>
            </div>
          </div>
        </div>
      </div>
      <OwlCarousel options={options}>
      <a href="case-studies/avniiscribe" onClick={handleClickGroupus}>
          <div className="case_box">
            <div className="content">
              <div className="image">
                <img src={Case4} alt="" />
              </div>
              <div className="links">
                <div className="text">AvniiScribe</div>
                <div className="tag_line">AvniScribe AI Integration Voice to text transcribe</div>
                <div className="store">
                  {/* <button>
                    <a
                      href="https://apps.apple.com/us/app/id1522541892"
                      target="_blank"
                    >
                      <img src={AppStore} alt="" />
                    </a>
                  </button>
                  <button>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.groupusmeetings&hl=en_IN"
                      target="_blank"
                    >
                      <img src={PlayStore} alt="" />
                    </a>
                  </button>
                  <button>
                    <a href="https://groupus.co/" target="_blank">
                      <img src={website} alt="" />
                    </a>
                  </button> */}
                </div>
              </div>
            </div>
            <div className="action">
              <Link to="case-studies/avniiscribe">
                See Details <img src={Go} alt="" />
              </Link>
            </div>
          </div>
        </a>
        <a href="case-studies/alltails" onClick={handleClickGroupus}>
          <div className="case_box">
            <div className="content">
              <div className="image">
                <img src={Case5} alt="" />
              </div>
              <div className="links">
                <div className="text">All Tails</div>
                <div className="tag_line">ALL SET FOR YOUR PET</div>
                <div className="store">
                  {/* <button>
                    <a
                      href="https://apps.apple.com/us/app/id1522541892"
                      target="_blank"
                    >
                      <img src={AppStore} alt="" />
                    </a>
                  </button>
                  <button>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.groupusmeetings&hl=en_IN"
                      target="_blank"
                    >
                      <img src={PlayStore} alt="" />
                    </a>
                  </button> */}
                  <button>
                    <a href="https://alltails.in/" target="_blank">
                      <img src={website} alt="" />
                    </a>
                  </button>
                </div>
                {/* <div className="industries">
                  <span className="indu1 ">Healthcare</span>
                  <span className="indu3">Education</span>
                  <span className="indu2">Consulting industry</span>
                </div> */}
              </div>
            </div>
            <div className="action">
              <Link to="case-studies/alltails">
                See Details <img src={Go} alt="" />
              </Link>
            </div>
          </div>
        </a>
        <a href="case-studies/GroupUs" onClick={handleClickGroupus}>
          <div className="case_box">
            <div className="content">
              <div className="image">
                <img src={Case1} alt="" />
              </div>
              <div className="links">
                <div className="text">GROUPUS</div>
                <div className="store">
                  <button>
                    <a
                      href="https://apps.apple.com/us/app/id1522541892"
                      target="_blank"
                    >
                      <img src={AppStore} alt="" />
                    </a>
                  </button>
                  <button>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.groupusmeetings&hl=en_IN"
                      target="_blank"
                    >
                      <img src={PlayStore} alt="" />
                    </a>
                  </button>
                  <button>
                    <a href="https://groupus.co/" target="_blank">
                      <img src={website} alt="" />
                    </a>
                  </button>
                </div>
                {/* <div className="industries">
                  <span className="indu1 ">Healthcare</span>
                  <span className="indu3">Education</span>
                  <span className="indu2">Consulting industry</span>
                </div> */}
              </div>
            </div>
            <div className="action">
              <Link to="case-studies/GroupUs">
                See Details <img src={Go} alt="" />
              </Link>
            </div>
          </div>
        </a>
        <a href="case-studies/Salamatk" onClick={handleClickSalamatk}>
          <div className="case_box">
            <div className="content">
              <div className="image">
                <img src={Case2} alt="" />
              </div>
              <div className="links">
                <div className="text">SALAMATK</div>
                <div className="store">
                  {/* <button><a href="" target='_blank'><img src={AppStore} alt="" /></a></button> */}
                  <button>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.salamatk.app"
                      target="_blank"
                    >
                      <img src={PlayStore} alt="" />
                    </a>
                  </button>
                </div>
                {/* <div className="industries">
                  <span className="indu1"> Auto insurance</span>
                  <span className="indu2">Logistics</span>
                  <span className="indu4">Automobile</span>
                </div> */}
              </div>
            </div>
            <div className="action">
              <Link to="case-studies/Salamatk">
                See Details <img src={Go} alt="" />
              </Link>
            </div>
          </div>
        </a>
        <a href="case-studies/Abees.in" onClick={handleClickAbees}>
          <div className="case_box">
            <div className="content">
              <div className="image">
                <img src={Case3} alt="" />
              </div>
              <div className="links">
                <div className="text">ABEES.IN</div>
                <div className="store">
                  <button>
                    {" "}
                    <a href="https://abees.in/" target="_blank">
                      <img src={website} alt="" />
                    </a>
                  </button>
                  {/* <button><img src={PlayStore} alt="" /></button> */}
                </div>
                {/* <div className="industries">
                  <span className="indu2">Garment retailers</span>
                </div> */}
              </div>
            </div>
            <div className="action">
              <Link to="case-studies/Abees.in">
                See Details <img src={Go} alt="" />
              </Link>
            </div>
          </div>
        </a>
      </OwlCarousel>
      <div className="container">
        <div className="col-md-12">
          <div className="view_all">
            <Link to="case-studies">
              View All Casestudy <img src={GoBlue} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
