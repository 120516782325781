import React from 'react'
import Footer from '../../../Components/Footer/Footer'
import Contect from '../../Home/Components/Contect'
import Navigation from '../../../Components/Navigation/Navigation'
import Image from './../../../assets/images/Lifestyle-KooIMind.png'
import AboutHeading from './AboutHeading'
import OurCulture from './OurCulture'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

export default function LifeatKoolMind() {

  useEffect(()=>{
    AOS.init({
      duration: 1500,
    });
  },[])
  return (
    <>
        <Navigation offer={true}/>
        <AboutHeading Title='Lifestyle @KoolMind' Image={Image}/>
        <section className="lifestyle">
            <div className="container">
              <div className="life_content">
                <div className="tag_line" data-aos="fade-up">At Koolmind, we believe that every team member brings unique skills, talents, and perspectives to the table. We value collaboration, respect, and open communication in everything we do.</div>
              </div>
            </div>
            <div className="life_style_wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-7">
                  <div className="life_box top" data-aos="fade-right">
                    <div className="life_img"><img src="images/lifestyle/worklifebalance.jpg" alt="" /></div>
                    <div className="life_box_content">
                      <div className="life_title">Work-life balance</div>
                      <div className="life_desc">Flexible work hours, 5-day work week, remote work options, great leave policy</div>
                    </div>
                  </div>
                  <div className="life_box" data-aos="fade-right">
                    <div className="life_img"><img src="images/lifestyle/culture.jpg" alt="" /></div>
                    <div className="life_box_content">
                      <div className="life_title">Fun and Supportive culture</div>
                      <div className="life_desc">Festival celebration events, positive and supportive work environment, strictly no to office politics</div>
                    </div>
                  </div>
                  <div className="life_box" data-aos="fade-right">
                    <div className="life_img"><img src="images/lifestyle/benefits.jpg" alt="" /></div>
                    <div className="life_box_content">
                      <div className="life_title">Competitive Compensation and Benefits</div>
                      <div className="life_desc">Competitive salaries and performance bonuses</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-5">
                  <div className="life_box top right" data-aos="fade-left">
                    <div className="life_img"><img src="images/lifestyle/professional.jpg" alt="" /></div>
                    <div className="life_box_content">
                      <div className="life_title">Professional Development</div>
                      <div className="life_desc">Training programs, workshops, mentorship opportunities.</div>
                    </div>
                  </div>
                  <div className="life_box" data-aos="fade-left">
                    <div className="life_img"><img src="images/lifestyle/projects.jpg" alt="" /></div>
                    <div className="life_box_content">
                      <div className="life_title">Innovative Projects</div>
                      <div className="life_desc">Cutting-edge technology and creativity, unique solutions to complex challenges.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurCulture/>
        <Contect/>
        <Footer/>
    </>
  )
}
