import React, { useEffect } from "react";
import CEO from "./../../../assets/images/ceo.jpg";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Content() {
    const options = {
        items: 1,
        nav: false,
        rewind: false,
        dots: true,
        loop: false,
        autoplay: true,
        margin: 0,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };
    useEffect(() => {
        AOS.init({
            duration: 1500,
        });
    }, []);
    return (
        <section className="about_content">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title" data-aos="fade-up">
                            <span>KoolMind Technolab</span> Your all-in-one partner for mobile apps, web apps, and software development services.
                        </div>
                        <div className="desc" data-aos="fade-up">
                            We redefine the essence of client partnerships. In a landscape where organizations invest substantial time in quest of an ideal technology collaborator for business elevation, we stand out by offering a seamless fusion of expertise and responsibility. We are one of the top quality custom mobile app & web app development company. With an advanced level of experience in developing WEB & MOBILE APPS for more than 10 Years, we have provided excellent solutions to our
                            clients and satisfied results have been given to our clients. We have delivered 80+ Mobile Apps & 25+ Softwares to our clients with 89% of repetition clients ratio.
                        </div>
                        <div className="about_image">
                            {/* <OwlCarousel options={options} >
                                <img src={AboutImg} alt="" />
                                <img src={AboutImg} alt="" />
                                <img src={AboutImg} alt="" />
                                <img src={AboutImg} alt="" />
                            </OwlCarousel> */}
                        </div>
                        <div className="word_ceo" data-aos="zoom-out-right" data-aos-duration="3000">
                            <div className="thumbnail">
                                <img src={CEO} alt="" />
                                <div className="play"></div>
                            </div>
                            <div className="content">
                                {/* <div className="mini_title">TESTIMONIALS</div> */}
                                <div className="ceo_title">Words of our CEO</div>
                                <div className="ceo_desc">
                                    At KoolMind Technolab, we go beyond being a service provider by embracing complete RESPONSIBILITY for our clients' success. In the complex world of technology partnerships, we recognize the need for a partner who not only delivers but also assumes full ownership of the journey. We don’t just handle mobile app development; we take it on with dedication, making your goals our mission. By choosing KoolMind Technolab, you’re partnering with a team that takes
                                    complete RESPONSIBILITY for your success—transforming every project into a PROMISE and a PARTNERSHIP.
                                </div>
                                <div className="name">Neel Mevada</div>
                                <div className="position">Founder & CEO</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
