import React from 'react'
import Vision from './../../../assets/images/vision.jpg'
import Mission from './../../../assets/images/mission.jpg'
import Tick from './../../../assets/images/tick.svg'

export default function VisionMission() {
  return (
    <section className='vision_mission'>
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-lg-6">
                    <div className="common_box vision">
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <div className="title">Our Vision</div>
                                <div className="desc">To be the most trustworthy technology partner using creative ideas to revolutionize companies by means of consistent mobile and web development. Every digital solution we provide should not only satisfy but also go beyond expectations, therefore promoting enduring alliances that propel industry-wide technological development and corporate success.</div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <div className="image"><img src={Vision} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-6">
                    <div className="common_box mission">
                        <div className="title">Our Mission</div>
                        <div className="desc">At KoolMind Technolab, we provide end-to-end mobile and web solutions. By means of our dedication to quality, we simplify difficult technological problems into feasible solutions empowering companies. From conception to execution, we stand out by fully owning every project so that our clients get reliable, creative ideas that propel their digital transformation and expansion.</div>
                        <div className="image"><img src={Mission} alt="" /></div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="common_box values">
                        <div className="row">
                            <div className="col-md-12 col-lg-3">
                                <div className="title">Our Values</div>
                            </div>
                            <div className="col-md-12 col-lg-9">
                                <div className="points">
                                    <div className="points_row"><img src={Tick}/> <span>Absolute Responsibility</span> - We take complete ownership of every project, treating each client's success as our personal mission</div>
                                    <div className="points_row"><img src={Tick}/> <span>Innovation with Purpose</span> - We pursue cutting-edge solutions that solve real business challenges and create meaningful impact</div>
                                    <div className="points_row"><img src={Tick}/> <span>Unwavering Reliability</span> - We build trust through consistent delivery, transparent communication, and steadfast support</div>
                                    <div className="points_row"><img src={Tick}/> <span>Excellence in Execution</span> - We maintain the highest standards in every aspect of our work, from planning to implementation</div>
                                    <div className="points_row"><img src={Tick}/> <span>Client-Centric Partnership</span> - We go beyond service provision to become true partners in our clients' success journey</div>
                                    <div className="points_row"><img src={Tick}/> <span>Continuous Growth</span> - We constantly evolve our skills and knowledge to stay ahead of technological advancements</div>
                                    <div className="points_row"><img src={Tick}/> <span>Quality Commitment</span> - We ensure every deliverable meets rigorous quality standards and exceeds industry expectations</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
