import React from 'react'
import healthCare from "./../../../assets/images/healthcare_pharma.png";
import education from "./../../../assets/images/education.png";
import homeCare from "./../../../assets/images/home_care.png";
import fitness from "./../../../assets/images/fitness.png";
import textile from "./../../../assets/images/textile.png";
import mobile from "./../../../assets/images/mobile_apps.png";

export default function IndustriesWorkedFor() {
  return (
    <>
    <div className="industries_worked">
        <div className="container">
            <div className='ind_title'>Industries We have worked for</div>
            <div className="row">
                <div className="col-md-12 col-lg-4">
                    <div className="ind_type">Top Industries</div>
                </div>
                <div className="col-md-12 col-lg-8">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ind_box big" style={{backgroundImage: `url(${healthCare})`}}>
                                <div className="name">Healthcare & Pharma</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ind_box big" style={{backgroundImage: `url(${education})`}}>
                                <div className="name">Education </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-lg-4">
                    <div className="ind_type">Other Industries</div>
                </div>
                <div className="col-md-12 col-lg-8">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ind_box" style={{backgroundImage: `url(${homeCare})`}}>
                                <div className="name">Home Care</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ind_box" style={{backgroundImage: `url(${fitness})`}}>
                                <div className="name">Fitness</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ind_box" style={{backgroundImage: `url(${textile})`}}>
                                <div className="name">Textile Garments</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="ind_box" style={{backgroundImage: `url(${mobile})`}}>
                                <div className="name">Mobile Apps</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
