import React, { useEffect, useRef, useState } from "react";
import BestOffer from "./BestOffer";
import { NavLink } from "react-router-dom";
import Logo from "./../../assets/images/logo.svg";
import Down from "./../../assets/images/down.svg";

export default function Navigation(props) {
  const { offer, myname } = props;
  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`nav_container ${show ? '' : 'hidden'} ${toggle ? "toggled" : ""}`}>
          {offer && <BestOffer />}
          <section className={`navigation`}>
            <div className="container">
              <div className="nav_wrapper">
                <div className="logo">
                  <NavLink to="/">
                    <img src={Logo} alt="" />
                  </NavLink>
                </div>
                <div className="links">
                  <NavLink to="/">Home</NavLink>
                  {/* <div className="drop_wrapper"  ref={dropdownRef}>
                      <button className={isOpen ? 'dropped' : ''} onClick={toggleDropdown}> About Us <img src={Down} alt="" /></button>
                      <div className={`dropdown_content ${isOpen ? 'active' : ''}`}>
                          <NavLink onClick={toggleDropdown} to='/our-story' className="content_row">Our Story</NavLink>
                          <NavLink onClick={toggleDropdown} to='/our-journey' className="content_row">Our Journey</NavLink>
                          <NavLink onClick={toggleDropdown} to='/life-at-koolmind' className="content_row">Life at KoolMind</NavLink>
                      </div>
                  </div> */}
                    {/* <div className="drop_wrapper"> */}
                      <NavLink to='/about' className="dropped"> About Us <img src={Down} alt="" />
                          <div className='dropdown_content'>
                              <NavLink onClick={toggleDropdown} to='/about' className="content_row">About Us</NavLink>
                              <NavLink onClick={toggleDropdown} to='/our-story' className="content_row">Our Story</NavLink>
                              <NavLink onClick={toggleDropdown} to='/our-journey' className="content_row">Our Journey</NavLink>
                              <NavLink onClick={toggleDropdown} to='/life-at-koolmind' className="content_row">Life at KoolMind</NavLink>
                              <NavLink onClick={toggleDropdown} to="/career" className="content_row">Career</NavLink>
                          </div>
                      </NavLink>
                    {/* </div> */}
                    <NavLink to="/services">Services</NavLink>
                    <NavLink to="/industries">Industries</NavLink>
                    <NavLink to="/our-works">Our Works</NavLink>
                    <NavLink to="/our-clients">Our Clients</NavLink>
                  </div>
                <div className="action">
                  <NavLink className="case_animation" to="/case-studies">Case Studies</NavLink>
                  <NavLink to="/contact">Contact Us</NavLink>
                  {/* <button><img src={Category} alt="" /></button> */}
                </div>
              </div>
            </div>
          </section>

          <section className="mobile_nav">
            <div className="nav_wrapper">
              <div className="logo">
                  <NavLink to="/">
                    <img src={Logo} alt="" />
                  </NavLink>
              </div>
              <div
                className={`toggle ${toggle ? "active" : ""}`}
                onClick={handleToggle}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className={`links ${toggle ? "active" : ""}`}>
                <NavLink to="/">Home</NavLink>
                {/* <NavLink to="/about">About Us</NavLink> */}
                <div className="drop_wrapper"  ref={dropdownRef}>
                    <button className={`link_button ${isOpen ? 'dropped' : ''}`} onClick={toggleDropdown}> About <img src={Down} alt="" /></button>
                    <div className={`dropdown_content ${isOpen ? 'active' : ''}`}>
                      <NavLink onClick={toggleDropdown} to='/about' className="content_row">About Us</NavLink>
                      <NavLink onClick={toggleDropdown} to='/our-story' className="content_row">Our Story</NavLink>
                      <NavLink onClick={toggleDropdown} to='/our-journey' className="content_row">Our Journey</NavLink>
                      <NavLink onClick={toggleDropdown} to='/life-at-koolmind' className="content_row">Life at KoolMind</NavLink>
                      <NavLink onClick={toggleDropdown} to="/career"  className="content_row">Career</NavLink>
                    </div>
                </div>
                <NavLink to="/services">Services</NavLink>
                <NavLink to="/industries">Industries</NavLink>
                <NavLink to="/our-works">Our Works</NavLink>
                <NavLink to="/our-clients">Our Clients</NavLink>
                <div className="action">
                  <NavLink to="/case-studies">Case Studies</NavLink>
                  <NavLink to="/contact">Contact Us</NavLink>
                  {/* <button>
                    <img src={Category} alt="" />
                  </button> */}
                </div>
              </div>
            </div>
          </section>
    </div>
  );
}
