import React,{useEffect} from 'react'
import Navigation from '../../Components/Navigation/Navigation'
import Footer from '../../Components/Footer/Footer'
import Hero from './Components/Hero'
import WhatWeBelive from "./Components/WhatWeBelive";
import WhyChooseUs from "./Components/WhyChooseUs";
import WhatCanWeDo from "./Components/WhatCanWeDo";
import CaseStudies from "./Components/CaseStudies";
import Clients from "./Components/Clients";
import ClientSpeak from "./Components/ClientSpeak";
import Contect from "./Components/Contect";
import "./Home.scss";
import ReactGA from "react-ga";
import AspiringIndustries from './Components/AspiringIndustries';
import IndustriesWorkedFor from './Components/IndustriesWorkedFor';

export default function Home() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <Navigation offer={true} />
      <Hero />
      <WhatWeBelive />
      <WhatCanWeDo />
      <IndustriesWorkedFor/>
      {/* <AspiringIndustries /> */}
      <CaseStudies />
      <WhyChooseUs />
      <ClientSpeak />
      <Clients />
      <Contect />
      <Footer />
    </>
  );
}
